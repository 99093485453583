import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Card, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const getOrgName1 = (org) => {
  if (org?.attribute4 && org?.attribute4 !== ".") {
    return `${org?.name} (${org?.customId}) (System ID: ${org?.attribute4})`;
  } else {
    return `${org?.name} (${org?.customId})`;
  }
};

const getOrgName2 = (org, surveyName) => {
  if (org?.attribute4 && org?.attribute4 !== ".") {
    return `${surveyName ? surveyName : ""}: ${org?.name} (${
      org?.customId
    }) (System ID: ${org?.attribute4})`;
  } else {
    return `${surveyName ? surveyName : ""}: ${org?.name} (${org?.customId})`;
  }
};

function Row({ row, activeFilings, handleSectionClick }) {
  const [open, setOpen] = React.useState(false);

  const selectableSurveyFilings =
    activeFilings?.selectableSurveyFilings?.filter(
      (filing) => filing?.surveyFiling?.survey?.id === row?.id
    );

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography style={{ fontWeight: "bold" }}>{row?.name} </Typography>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableBody>
                  {selectableSurveyFilings?.map((filing) => (
                    <TableRow key={filing.surveyFiling.id}>
                      <TableCell sx={{ width: "10%" }}></TableCell>
                      <TableCell sx={{ width: "50%" }}>
                        <Link
                          to="#"
                          onClick={() => handleSectionClick(filing)}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.color = "#003087")
                          }
                          onMouseOut={(e) => (e.target.style.color = "inherit")}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            {getOrgName1(filing?.surveyOrganization)}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        {filing?.surveyFilingStatus}
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        {dayjs
                          .utc(filing?.surveyFiling?.dateModified)
                          .local()
                          .format("MM-DD-YYYY hh:mm A")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function FilingsTable({ activeFilings, handleSectionClick }) {
  const surveyCounts = activeFilings?.activeSurveys?.reduce((acc, survey) => {
    const surveyId = survey?.id;
    const count = activeFilings?.selectableSurveyFilings?.filter(
      (filing) => filing?.surveyFiling?.survey?.id === surveyId
    )?.length;
    acc[surveyId] = count;
    return acc;
  }, {});

  return (
    <>
      {Object.values(surveyCounts)?.some((value) => value > 1) &&
      activeFilings?.activeSurveys?.length > 1 ? (
        <TableContainer
          component={Card}
          sx={{
            margin: "20px 0",
            borderRadius: "16px",
            boxShadow: "0px 4px 6px 0px rgba(62, 73, 84, 0.04)",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "10%" }} />
                <TableCell sx={{ width: "50%" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Status</Typography>
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Last modified
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeFilings?.activeSurveys?.map((survey) => (
                <Row
                  key={survey?.id}
                  row={{
                    id: survey?.id,
                    name: survey?.name,
                  }}
                  activeFilings={activeFilings}
                  handleSectionClick={handleSectionClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer
          component={Card}
          sx={{
            margin: "20px 0",
            borderRadius: "16px",
            boxShadow: "0px 4px 6px 0px rgba(62, 73, 84, 0.04)",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: "15px 30px " }}>
                  <Typography style={{ fontWeight: "bold" }}>Name</Typography>
                </TableCell>
                <TableCell style={{ padding: "15px 30px " }}>
                  <Typography style={{ fontWeight: "bold" }}>Status</Typography>
                </TableCell>
                <TableCell style={{ padding: "15px 30px " }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Last modified
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeFilings?.selectableSurveyFilings?.map((filing, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell style={{ width: "50%", padding: "15px 30px " }}>
                      <Link
                        to="#"
                        onClick={() => handleSectionClick(filing)}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                        onMouseOver={(e) => (e.target.style.color = "#003087")}
                        onMouseOut={(e) => (e.target.style.color = "inherit")}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {getOrgName2(
                            filing?.surveyOrganization,
                            filing?.surveyFiling?.survey?.name
                          )}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell style={{ padding: "15px 30px " }}>
                      {filing?.surveyFilingStatus}
                    </TableCell>
                    <TableCell style={{ padding: "15px 30px " }}>
                      {dayjs
                        .utc(filing?.surveyFiling?.dateModified)
                        .local()
                        .format("MM-DD-YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
